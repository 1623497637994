import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

function IVOSolutions() {

  return (
    <div className="px-0 pb-16 mb-0 py-4 sm:p-8 sm:mb-20 lg:p-8">

      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-6 lg:gap-7 w-full z-10">

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="/gps-tracker-malaysia/" target="_blank">
            <div>
              <StaticImage
                alt="GPS Tracking System / Automated Vehicle Locating System (AVLS)"
                src="../../../static/images/mdvr/upgrade/img-1.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">GPS Tracking System / Automated Vehicle Locating System (AVLS)</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="/fleet-management-malaysia/">
            <div>
              <StaticImage
                alt="Fleet Management System (FMS)"
                src="../../../static/images/mdvr/upgrade/img-2.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Fleet Management System (FMS)</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="/driver-id/">
            <div>
              <StaticImage
                alt="Driver Identification & Management System"
                src="../../../static/images/mdvr/upgrade/img-3.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Driver Identification & Management System</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="/fuel/">
            <div>
              <StaticImage
                alt="Diesel / Fuel Monitoring System "
                src="../../../static/images/mdvr/upgrade/img-4.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Diesel / Fuel Monitoring System </h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="/ev/" target="_blank">
            <div>
              <StaticImage
                alt="Fleet Electrification Solutions (EVX)"
                src="../../../static/images/mdvr/upgrade/img-5.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Fleet Electrification Solutions (EVX)</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="https://apps.katsana.com/get-quotation/" target="_blank">
            <div>
              <StaticImage
                alt="Integrated Vehicle Operation Solutions (IVO)"
                src="../../../static/images/mdvr/upgrade/img-6.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Integrated Vehicle Operation Solutions (IVO)</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

      </div>

    </div>

    
  );
};

export default IVOSolutions;
